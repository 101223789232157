<template>
  <trac-loading class="pt-64" v-if="loading" />
  <main v-else>
    <h2 class="text-sm">Tickets</h2>
    <h1 class="font-medium mt-3">Tickets History</h1>
    <div class="mt-10">
      <main id="table">
        <div class="table-shadow p-6 rounded-md">
          <div class="flex w-full items-center">
            <div class="relative max-w-sm rounded-md w-full flex items-center">
              <input type="text" placeholder="Search Ticket Name" data-test="search-ticket-button" class="
                  input-shadow
                  py-2
                  w-full
                  pl-5
                  text-xs
                  focus:outline-none
                  leading-7
                  tracking-wide
                  text-primaryGray
                " v-model="search" />
              <svg class="w-4 h-4 absolute right-0 mr-6" viewBox="0 0 14 14" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <circle cx="6.56705" cy="6.61686" r="5.39909" stroke="#253B95" stroke-width="1.06786"
                  stroke-linecap="round" stroke-linejoin="round" />
                <path d="M13.3156 13.3659L10.3799 10.4302" stroke="#253B95" stroke-width="1.06786" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </div>
            <div class="ml-8 relative">
              <button data-test="add-filter-button" v-click-outside="close" @click="active = !active" id="options-menu"
                aria-haspopup="true" aria-expanded="true" type="button" class="
                  border
                  smallest
                  text-primaryBlue
                  focus:outline-none
                  border-primaryBlue
                  py-2
                  px-4
                  rounded
                  inline-flex
                  w-32
                  justify-between
                  capitalize
                  items-center
                ">
                Add filter
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </button>
              <div v-show="active" @click="active = false" class="
                  absolute
                  white-gradient
                  px-4
                  py-6
                  max-w-sm
                  width
                  bg-white
                  mt-1
                  rounded-md
                ">
                <div class="flex w-full justify-between">
                  <div class="w-full ml-8">
                    <p class="text-xs font-medium">By Date</p>
                    <div class="mt-2 flex items-center">
                      <input type="radio" v-model="time" value="all" name="" id="" />
                      <p class="text-xs ml-3 leading-7">All</p>
                    </div>
                    <div class="mt-2 flex items-center">
                      <input type="radio" v-model="time" value="today" name="" id="" />
                      <p class="text-xs ml-3 leading-7">Today</p>
                    </div>
                    <div class="mt-2 flex items-center">
                      <input type="radio" value="currentMonth" v-model="time" name="" id="" />
                      <p class="text-xs ml-3 leading-7">This Month</p>
                    </div>
                    <div class="mt-2 flex items-center">
                      <input type="radio" name="" value="lastMonth" v-model="time" id="" />
                      <p class="text-xs ml-3 leading-7">Last Month</p>
                    </div>
                    <div @click.stop="toggle = !toggle" class="mt-2 flex relative items-center">
                      <svg class="w-3 h-3" :class="{
                          'fill-current text-blue-500': time == 'custom',
                        }" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.975586" y="2.43408" width="12.7281" height="12.2995" rx="1.65333" stroke="#253B95"
                          stroke-width="1.23999" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M10.168 1.06738V3.8006" stroke="#253B95" stroke-width="1.23999" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M4.51079 1.06738V3.8006" stroke="#253B95" stroke-width="1.23999" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M0.975586 6.53404H13.7037" stroke="#253B95" stroke-width="1.23999" stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>

                      <p @click="open = !open" class="
                          text-xs
                          ml-3
                          leading-7
                          text-primaryBlue
                          cursor-pointer
                        ">
                        Select Date
                      </p>
                      <div class="relative">
                        <date-picker ref="datepicker" v-model="custom" :inline="true" v-if="open" @change="handleChange"
                          format="YYYY-MM-DD" class="absolute shadow-md curr" range></date-picker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex items-center ml-5">
              <svg class="w-4 h-4" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3.68262 13.042V28.042H10.7139V21.4795V21.4795C10.7139 19.4084 12.2879 17.7295 14.2295 17.7295C16.1711 17.7295 17.7451 19.4084 17.7451 21.4795V21.4795V28.042H24.7764V13.042"
                  stroke="#003283" stroke-width="1.65" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M1.0459 28.042H27.4131" stroke="#003283" stroke-width="1.65" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M1.23099 10.3282H1.23099C0.796712 11.2544 1.14853 12.3807 2.01679 12.8439C2.26109 12.9743 2.5305 13.0421 2.80365 13.042H25.6552C26.626 13.0423 27.4133 12.2031 27.4135 11.1676C27.4136 10.8762 27.3501 10.5888 27.2279 10.3282L23.8974 5.54199H4.56146L1.23099 10.3282Z"
                  fill="#B4E6FF" stroke="#003283" stroke-width="1.65" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M4.56104 5.54102V3.66602L4.56104 3.66602C4.56104 2.63048 5.34803 1.79102 6.31885 1.79102H22.1392V1.79102C23.11 1.79102 23.897 2.63048 23.897 3.66602C23.897 3.66602 23.897 3.66602 23.897 3.66602V5.54102"
                  stroke="#003283" stroke-width="1.65" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <div class="ml-4">
                <trac-dropdown data-test="location-button" :choice="true" title="Select Store" :menuItems="storeData"
                  v-model="store">
                </trac-dropdown>
              </div>
            </div>
          </div>

          <div>
            <div class="w-full mt-8 max-area">
              <div>
                <div :style="`grid-template-columns:1fr 1fr 1fr 1fr 1fr 1fr`" class="
                    grid
                    rounded-tl-md rounded-tr-md
                    border
                    bg-accentLight
                    text-xs
                    font-medium
                    py-4
                    pl-10
                  ">
                  <div>Date</div>
                  <div>Ticket Name</div>
                  <div>Sale Agent</div>
                  <div>Customer Name</div>
                  <div>No Of Items</div>
                  <div>Total Price</div>
                </div>
              </div>
              <div v-if="tickets != null && tickets.length">
                <div :style="`grid-template-columns:1fr 1fr 1fr  1fr 1fr 1fr `" class="
                    grid
                    last:border-b
                    border-l
                    hov
                    cursor-pointer
                    border-r
                    even:bg-veryLight
                    items-center
                  " v-for="(ticket, i) in tickets" :key="i" @click="setTicket(ticket)">
                  <div class="
                      w-full
                      flex
                      items-center
                      py-5
                      pl-8
                      text-xs text-accentDark
                      font-normal
                    ">
                    {{ moment(ticket.created_at).format("Do-MMM-YYYY") }}
                  </div>
                  <div class="
                      w-full
                      py-5
                      pl-8
                      text-xs
                      font-medium
                      capitalize
                      text-accentDark
                      flex-col
                    ">
                    {{ ticket.ticket_name }}
                  </div>
                  <div class="
                      w-full
                      py-5
                      pl-8
                      text-xs
                      font-medium
                      capitalize
                      text-accentDark
                      flex-col
                    ">
                    {{ ticket.sale_agent }}
                  </div>
                  <div class="
                      w-full
                      py-5
                      pl-8
                      text-xs
                      font-medium
                      capitalize
                      text-accentDark
                      flex-col
                    ">
                    {{ ticket.customer_name ? ticket.customer_name : "N/A" }}
                  </div>
                  <div class="
                      w-full
                      py-5
                      pl-8
                      text-xs text-accentDark
                      font-normal
                      flex-col
                    ">
                    {{ ticket.items.length }}
                  </div>
                  <div class="
                      w-full
                      py-5
                      pl-8
                      text-xs text-accentDark
                      font-normal
                      flex-col
                    ">
                    {{ ticket.total_price | formatPrice }}
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="border py-3 pl-5 text-xs border-t-0">
                  <trac-center-data>
                    <div class="h-40 flex items-center text-lg text-gray-600">
                      No ticket added yet.
                    </div>
                  </trac-center-data>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </main>
</template>

<script>
import Fuse from "fuse.js";
import ClickOutside from "vue-click-outside";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import { mapGetters } from "vuex";
import { GET_USER_DATA } from "../../browser-db-config/localStorage";
import { eventBus } from "./../../main";

export default {
  directives: {
    ClickOutside,
  },

  components: { DatePicker },
  data() {
    return {
      toggle: false,
      loading: false,
      active: false,
      open: false,
      items: [],
      search: "",
      time: "today",
      custom: "",
      moment,
      payments: [],
      filtered: null,
      original: null,
      getTableHeader: [
        "Date",
        "Customer",
        "Receipt No",
        "Transaction Type",
        "No Of Items",
        "Sales Amount",
        "Payment Methiod",
      ],
      modalObject: null,
      openReceipt: false,
      salesHistory: [],
      allSalesHistory: [],
      storeData: [],
      store: {
        _id: "",
        name: "",
      },
      tickets: [],
      allTickets: [],
    };
  },
  watch: {
    search(x) {
      if (x !== "") {
        this.tickets = [...this.allTickets].filter((b) => {
          return b.ticket_name.toLowerCase().includes(x.toLowerCase());
        });
      } else {
        return (this.tickets = [...this.allTickets]);
      }
    },
    store(x) {
      this.fetchTickets();
    },
    custom: {
      handler(x, y) {
        this.time = "custom";
        let payload = {};
        payload.startDate = this.moment(this.custom[0]).format("YYYY-MM-DD");
        payload.endDate = this.moment(this.custom[1]).format("YYYY-MM-DD");
        this.tickets = [...this.allTickets].filter((x) => {
          return (
            this.moment(x.created_at).format("YYYY-MM-DD") >=
            payload.startDate &&
            this.moment(x.created_at).format("YYYY-MM-DD") <= payload.endDate
          );
        });
        // this.$refs.datepicker.closePopup()
        // this.active = false
      },
    },
    time: {
      immediate: true,
      handler(x, y) {
        let payload = {};
        switch (x) {
          case "all":
            this.tickets = [...this.allTickets];
            break;
          case "today":
            payload.startDate = this.moment().format("YYYY-MM-DD");
            payload.endDate = this.moment().format("YYYY-MM-DD");
            this.tickets = [...this.allTickets].filter((x) => {
              return (
                this.moment(x.created_at).format("YYYY-MM-DD") ===
                payload.startDate
              );
            });

            break;
          case "currentMonth":
            payload.startDate = this.moment()
              .startOf("month")
              .format("YYYY-MM-DD");
            payload.endDate = this.moment().format("YYYY-MM-DD");
            this.tickets = [...this.allTickets].filter((x) => {
              let date = this.moment(x.created_at).format("YYYY-MM-DD");
              return date >= payload.startDate && date <= payload.endDate;
            });
            break;
          case "lastMonth":
            payload.startDate = this.moment()
              .subtract(1, "months")
              .startOf("month")
              .format("YYYY-MM-DD");
            payload.endDate = this.moment()
              .subtract(1, "months")
              .endOf("month")
              .format("YYYY-MM-DD");
            this.tickets = [...this.allTickets].filter((x) => {
              let date = this.moment(x.created_at).format("YYYY-MM-DD");
              return date >= payload.startDate && date <= payload.endDate;
            });
            break;
          case "custom":
            payload.startDate = this.moment(this.custom[0]).format(
              "YYYY-MM-DD"
            );
            payload.endDate = this.moment(this.custom[1]).format("YYYY-MM-DD");
            this.tickets = [...this.allTickets].filter((x) => {
              return (
                this.moment(x.created_at).format("YYYY-MM-DD") >=
                payload.startDate &&
                this.moment(x.created_at).format("YYYY-MM-DD") <=
                payload.endDate
              );
            });
            break;
          default:
            break;
        }
      },
    },
  },

  methods: {
    setTicket(x) {
      this.$store.commit("SET_CURRENT_TICKET", x);
      this.$router.push({ name: "ViewTicket" });
    },
    handleChange(x, y) {
      if (x || y) {
        this.open = false;
      }
    },
    openModal(x) {
      this.modal = true;
      this.modalObject = x;
    },
    close() {
      this.active = false;
    },
    popModal(x) {
      this.modal = true;
    },
    repeat(str, num) {
      var holder = [];
      for (var i = 0; i < num; i++) {
        holder.push(str);
      }
      return holder.join(" ");
    },

    openDate() {
      this.$refs.calender.$el.click();
    },

    titleCase(string) {
      let sentence = string.toLowerCase().split(" ");
      for (let i = 0; i < sentence.length; i++) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
      }
      return sentence.join(" ");
    },
    async salesHistoryRequest() {
      this.loading = true;
      let payload = {};
      payload._id = this.store.id;
      // let res = await this.$store.dispatch("FETCH_SALES_HISTORY", payload);
      await this.$store.dispatch("FETCH_SALES_HISTORY", payload);
      let res = this.$store.getters["GET_ALL_SALES_HISTORY"];
      if (res.status && res.data) {
        this.salesHistory = res.data.salesHistory || [];
        this.allSalesHistory = res.data.salesHistory || [];
        this.time = "today";
        this.salesHistory = [...this.allSalesHistory].filter((x) => {
          return (
            this.moment(x.created_at).format("YYYY-MM-DD") ===
            this.moment().format("YYYY-MM-DD")
          );
        });

        this.loading = false;
      } else {
        this.salesHistory = [];
        this.allSalesHistory = [];
        this.loading = false;
      }
    },
    async fetchTickets() {
      this.loading = true;
      let res = await this.$store.dispatch("FETCH_UNPAID_SALES", this.store);
      if (res && res.status) {
        const tickets = res.data || [];
        this.time = "today";
        let newTickets = tickets.filter((ticket) => {
          return ticket.ticket_name !== "";
        });
        let b = newTickets.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        this.tickets = b;
        this.allTickets = newTickets;
      } else {
        // alert("There are currently no tickets");
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.loading = false;
    },
  },
  computed: {
    initials() {
      let k = [...this.payments.list_of_payments];

      let b = k.map((u) => {
        return this.getInitials(u.name);
      });
      return b;
    },
  },

  async created() {
    // (GET_USER_DATA().stores, 'wome')
    // let stores = GET_USER_DATA().stores;

    await this.$store.dispatch("FETCH_ALL_STORES");
    const res = this.$store.getters["GET_ALL_STORES"];
    if (res.status) {
      let updatedStore = res.data || [];
      this.storeData = updatedStore;
      // updatedStore, "smite";
      // (this.storeData[0].id, "oso");
      this.store._id = this.storeData[0]._id;
      this.store.name = this.storeData[0].name;
    }
    await this.fetchTickets();

    // this.storeData = k;
  },
};
</script>

<style>
.dd {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.hov:hover {
  background: rgba(190, 182, 182, 0.173);
}

.max-area {
  max-height: 70vh;
  overflow-y: auto;
}

.width {
  width: 22rem;
}
</style>
